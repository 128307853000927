import * as React from "react"
import Layout from "../components/layout"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"
import FeatherIcon from 'feather-icons-react';
import LogoDiamond from "../components/LogoDiamond"
import { StaticImage } from "gatsby-plugin-image"
import FadeUp from "../components/fadeUp"
import WarmerLines from "../components/WarmerLines"


const JustPage = () => (
  
  <Layout>

    <Helmet>
      <body className="body-together"></body>
    </Helmet>

<section className="grid grid-cols-1 lg:grid-cols-2">

    <div className="h-screen/2 lg:h-screen jkh">

    </div>

    <div className="flex lg:h-screen items-center justify-center order-last lg:order-first bg-owlwhite p-4 lg:p-8 text-center lg:text-left">
      <motion.div className="md:w-2/3"
        initial={{x: -300, opacity: 0}}
        animate={{x: 0, opacity: 1}}
        transition={{duration: 1}}
      >
        <div className="w-4 my-4 mx-auto lg:mx-7 animate-bounce"><LogoDiamond fill="#E63946"/></div>  
        <h1 className="display-1 w-full text-7xl xl:text-8xl text-owlgreen-dark">JUST</h1>
        <motion.hr 
          initial={{width: 0}}
          animate={{width: "100%"}}
          transition={{duration: 1.5}}                  
        />   
        <p className="text-owlgray">Brand creation | Logo design | Website Design &amp; build | Email campaigns</p>
      </motion.div>
    </div>
    
</section>          

<section class="section text-owlwhite">
    <div class="grid sm:grid-cols-2 gap-4">
      <FadeUp className="flex items-center justify-center">
        <div className="w-2/3">
          <h2 className="display-3 border-b py-2">About Just</h2>
          <p>JKH were a new business start-up with a small budget to spend on a website and associated marketing to start generating leads.</p>
        </div>
      </FadeUp>

      <FadeUp className="flex items-center justify-center">
        <div className="w-2/3">
          <h2 className="display-3 border-b py-2">Our work</h2>
          <p>To make their money go further, we created a simple website that was designed to grow as the business does and worked on targeted email campaigns. As they are growing, we are working on new areas with them.</p>
        </div>
      </FadeUp>
        
    </div>      
</section>

<section class="section bg-jkhorange">
    <div class="container">

        <div className="w-100 flex justify-center">
          <StaticImage
            src="../images/JUST_ufh_logo.svg"
            alt="JUST Logo"
            layout="constrained"
            />          
        </div>

        <h1 className="display-1 border-t text-owlwhite text-center pt-8">New start-up looking to maximise impact on a budget.</h1>
        </div>  

        <FadeUp className="flex items-center justify-center">
          <a href="https://justufh.co.uk/" className="btn-yellow w-48 py-4 mt-5" target="_blank" rel="noreferrer">
            <div className="display-3">Visit website
            <FeatherIcon icon="external-link" size="24" className="stroke-owlwhite -mt-0.5 ml-3 float-right"/>    
            </div>
          </a>
      </FadeUp>  

</section>
            
   
<section className="section">
    <div className="container">

    <div class="flex">
          <StaticImage
            src="../images/jkh_stock.jpg"
            alt="JKH Stock Image"
            layout="constrained"
          />             
        </div>                  
    </div>
</section>       
      

<section class="section section-white">
  <div className="container">
    <div className="flex items-center justify-center">            
        <div className="w-full lg:w-1/2 p-4">
            <WarmerLines />
        </div>  
    </div>    
  </div>
</section>          

<section className="section section-testimonial">
    <div className="container">
        <div className="w-full md:w-1/2 mx-auto">
            <div className="text-right">
                <h1 className="text-lg font-thin sm:text-4xl text-center">"Jay and Lisa are brilliant to work with - they provided great advice throughout the design and implentation of my first website, and were able to realise all of my ideas
                The website is generating traffic and leads on a regular basis.
                I'll definitely use them again. <br/>Thank you 2 Owls!"</h1>
                <hr />                
                <h1 className="text-lg sm:text-4xl">- Just Underfloor Heating</h1>
            </div>            
        </div>    
    </div>
</section>             



  </Layout>
)

export default JustPage
