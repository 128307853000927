import React from 'react';
import { motion } from 'framer-motion';

const wrapper = {
    visible: { 
        opacity: 1, y: 0,
        transition: {
            when: "beforeChildren",
            delayChildren: 0.1,
        },
    },
    hidden: { 
        opacity: 1, y: 10,
        transition: {
            when: "afterChildren",
        },
    },

  }

const variants = {
    visible: { strokeOpacity: 1, pathLength: 1, pathOffset: 0 },
    hidden: { strokeOpacity: 0.5, pathLength: 0, pathOffset: 1 },

  }

const WarmerLines = (props) => (

    <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4180 5188" className={props.className}
        initial="hidden"
        whileInView="visible" 
        viewport={{ once: true, amount: 0 }}            
        variants={wrapper}
    >

    <motion.path d="M4107.04,0c0,144-152.04,144-152.04,144,0,0,9,0-135,0s-144,144-144,144c0,0,0-18,0,144s144,144,144,144h144c144,0,144,144,144,144v1440c0,288-288,288-288,288h-216c-72,0-144-72-144-72l-432-432c-72-72-144-72-144-72h-360c-144,0-144-144-144-144V432c0-135,144-144,144-144h720c144,0,144,144,144,144v288c0,144,144,144,144,144h144c144,0,144,144,144,144v936s0,72-72,72-72-72-72-72v-504c0-144-144-144-144-144h-504c-72,0-72-72-72-72v-432s0-72-72-72-72,72-72,72v576s0,72,72,72h576s72,0,72,72v216s0,72-72,72c0,0-72,0-72-72v-72s0-72-72-72h-504s-144,0-144-144V720s-9-144,144-144,144,0,144,0c0,0,144,0,144,144v288s0,144,144,144h360s72,0,72-72c0,0,9-72-72-72h-216s-144,0-144-144v-288s18-144-144-144h-432s-144,0-144,144v1008s0,144,144,144h360s72,0,144,72l360,360c72,72,216,72,216,72,0,0,216,0,216-216V864s0-144-144-144h-144s-144,0-144-144V288s0-144-144-144h-1008s-144,0-144,144V1872s0,144,144,144h360s72,0,144,72l441,432s72,72,144,72h567s72,0,72-72,72-72,72-72" 
    fill="none" 
    stroke="#F8B637" 
    stroke-miterlimit="10" 
    strokeWidth="10"
    variants={variants}
    transition={{duration: 3}}   
    />

    <motion.path d="M4180,3025.12c-72,0-72-73.12-72-73.12v-135s0-72-72-72c-72,0-72,72-72,72v135s0,72-72,72c-72,0-72-72-72-72v-135s0-72-72-72c-72,0-72,72-72,72v135s0,72-72,72-72-72-72-72v-135s0-72-72-72c-72,0-72,72-72,72v135c0,72-72,72-72,72h-792c-144,0-144,144-144,144v1296c0,144,144,144,144,144h1224c144,0,144-144,144-144v-1008c0-144-144-144-144-144h-935.9c-144,0-144.1,144-144.1,144v720c0,144,144,144,144,144h648c144,0,144-144,144-144v-432c0-144-144-144-144-144h-360c-144,0-144,144-144,144v144c0,144,144,144,144,144h144c72,0,72-72,72-72,0,0,0-72-72-72h-72s-72,0-72-72c0-72,72-72,72-72h144s144,0,144,144v144s0,144-144,144h-360s-144,0-144-144v-432s0-144,144-144h648s144,0,144,144v720s0,144-144,144h-936s-144,0-144-144v-1008s0-144,144-144h1224s144,0,144,144v1296s9,144-144,144h-1512s-144,0-144-144v-1584s0-144,144-144h792.2s71.8-9,71.8-72-72-72-72-72H1588s-72,0-72-72,72-72,72-72h1440s72,0,72-72-72-72-72-72H1228s-72,0-72-72,72-72,72-72h1440s72,0,72-72-72-72-72-72H1084s-72-9-72-72,71.8-72,71.8-72h792.2c144,0,144-144,144-144V288c0-144-144-144-144-144H364c-153,0-144,144-144,144V1584c0,144,144,144,144,144H1588c144,0,144-144,144-144V576c0-144-144-144-144-144H652c-144,0-144,144-144,144v720c0,144,144,144,144,144h648c144,0,144-144,144-144v-432c0-144-144-144-144-144h-360c-144,0-144,144-144,144v144c0,144,144,144,144,144h144s72,0,72-72-72-72-72-72h-72c-72,0-72-72-72-72,0,0,0-72,72-72h144s144,0,144,144v144s0,144-144,144h-360s-144,0-144-144v-432s0-144,144-144h648s144,0,144,144v720s-.1,144-144.1,144H508s-144,0-144-144V432s0-144,144-144H1732s144,0,144,144V1728s0,144-144,144H940s-72,0-72,72v135s0,72-72,72-72-72-72-72v-135s0-72-72-72-72,72-72,72v135s0,72-72,72-72-72-72-72v-135s0-72-72-72-72,72-72,72v135s0,72-72,72-72-72-72-72v-135s0-73.12-72-73.12c0,0-72,1.12-72-70.88v0c0,72,72,70.88,72,70.88,72,0,72,73.12,72,73.12v135s0,72,72,72,72-72,72-72v-135s0-72,72-72,72,72,72,72v135s0,72,72,72,72-72,72-72v-135s0-72,72-72,72,72,72,72v135s0,72,72,72,72-72,72-72v-135c0-72,72-72,72-72h792"
    fill="none" 
    stroke="#F8B637" 
    strokeWidth="10"
    variants={variants}
    transition={{duration: 4}}   
    />

    <motion.path d="M4036,5040H2020s-72,0-72-72-72-72-72-72c0,0-72-9-72,72s-72,72-72,72c0,0-72,0-72-72s-72-72-72-72c0,0-72,0-72,72s-72,72-72,72c0,0-72,9-72-72s-72-72-72-72c0,0-72,0-72,72s-72,72-72,72c0,0-72,0-72-72s-72-72-72-72c0,0-72,0-72,72s-72,72-72,72H292c-144,0-143.04-144-143.04-144,0-144,152.04-144,152.04-144,0,0-9,0,135,0,144,0,144-144,144-144,0,0,0,18,0-144,0-144-144-144-144-144h-144c-144,0-144-144-144-144v-1440c0-288,288-288,288-288h216c72,0,144,72,144,72l432,432c72,72,144,72,144,72h360c144,0,144,144,144,144v1296c0,135-144,144-144,144H1012c-144,0-144-144-144-144v-288c0-144-144-144-144-144h-144c-144,0-144-144-144-144v-936s0-72,72-72,72,72,72,72v504c0,144,144,144,144,144h504c72,0,72,72,72,72v432s0,72,72,72c72,0,72-72,72-72v-576s0-72-72-72H796s-72,0-72-72v-216s0-72,72-72c0,0,72,0,72,72v72s0,72,72,72h504s144,0,144,144v720s9,144-144,144c-153,0-144,0-144,0,0,0-144,0-144-144v-288s0-144-144-144h-360s-72,0-72,72c0,0-9,72,72,72h216s144,0,144,144v288s-18,144,144,144h432s144,0,144-144v-1008s0-144-144-144h-360s-72,0-144-72l-360-360c-72-72-216-72-216-72,0,0-216,0-216,216v1152s0,144,144,144h144s144,0,144,144v288s0,144,144,144h1008s144,0,144-144v-1584s0-144-144-144h-360s-72,0-144-72l-441-432s-72-72-144-72l-566,.28s-72-.28-72,71.72-73,72-73,72c0,0-72,0-72,72v2520s0,144,144,144H4036"
    fill="none" 
    stroke="#F8B637" 
    stroke-miterlimit="10" 
    strokeWidth="10"
    variants={variants}
    transition={{duration: 5}}   
    />

    </motion.svg>

);

export default WarmerLines;
